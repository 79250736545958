import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Select } from 'antd';
import './dropdown.scss';
import MicRecorder from 'mic-recorder-to-mp3';
import { server_url } from '../../Config';
import { cases } from './cases.js';
import { CaretDownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp, faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { Switch } from "antd";

let MALE_VOICES = ['alloy', 'echo', 'onyx'];
let FEMALE_VOICES = ['fable', 'nova', 'shimmer'];
let VOICE_NAME = '';


function Selector({ theme }) {
  const [isRecording, setIsRecording] = useState(false);
  const [Mp3Recorder, setMp3Recorder] = useState(
    new MicRecorder({ bitRate: 128 })
  );
  const [options, setOptions] = useState([]);
  const [selectedOptionValue, setSelectedOptionValue] = useState();
  const [selectedResValue, setSelectedResValue] = useState();
  const [resData, setResData] = useState([]);
  const [feedback, setFeedback] = useState();
  const [error, setError] = useState();
  const [resError, setResError] = useState();
  const [isConversationEnded, setConversationEnded] = useState(false);
  const [isFeedbackEnabled, setFeedbackEnabled] = useState(false);
  const [feedbackError, setFeedbackError] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const noRender = useRef(false);

  const handleChange = (value) => {
    setConversationEnded(false);
    setFeedbackEnabled(false);
    setFeedback('');
    setFeedbackError('');
    setResData([]);

    let memory = [{ role: 'system', content: '' }];
    let tokens = 0;
    let maxTokens = 4000;
    let history = [];

    let historyString = JSON.stringify(history);
    let memoryString = JSON.stringify(memory);

    sessionStorage.setItem('history', historyString);
    sessionStorage.setItem('memory', memoryString);
    sessionStorage.setItem('tokens', tokens.toString());
    sessionStorage.setItem('maxTokens', maxTokens.toString());

    setResData([]);
    setSelectedOptionValue(value);
    const selectedValue = value;

    const body = {
      option: selectedValue,
      memory: JSON.parse(sessionStorage.getItem('memory')),
      // maxTokens: parseInt(sessionStorage.getItem("maxTokens")),
      tokens: parseInt(sessionStorage.getItem('tokens')),
      history: JSON.parse(sessionStorage.getItem("history")),
    };

    if (cases[selectedValue][0]?.voice === 'male') {
      const randomIndex = Math.floor(Math.random() * MALE_VOICES.length);
      VOICE_NAME = MALE_VOICES[randomIndex];
    } else {
      const randomIndex = Math.floor(Math.random() * FEMALE_VOICES.length);
      VOICE_NAME = FEMALE_VOICES[randomIndex];
    }

    axios
      .post(server_url + 'createprompt', body)
      .then(async (res) => {
        setSelectedResValue(res?.data?.text);

        await sessionStorage.setItem('memory', JSON.stringify(res.data.memory));
        await sessionStorage.setItem('tokens', res.data.tokens.toString());
        await sessionStorage.setItem(
          "history",
          JSON.stringify(res.data.history)
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleTextToSpeech = async (text) => {
    console.log('handleTextToSpeech isMuted before fetch:', isMuted);
    try {
      const mp3 = await fetch(
        'https://api.openai.com/v1/audio/speech',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
          },
          body: JSON.stringify({
            model: 'tts-1',
            voice: VOICE_NAME,
            input: text,
            speed: 1,
          }),
        }
      );
      const audioBlob = await mp3.blob();
      const audioURL = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioURL);
      console.log('handleTextToSpeech isMuted before setting muted:', isMuted);
      audio.muted = isMuted;
      console.log('Audio object muted property after setting:', audio.muted);
      if (!audio.muted) {
        await audio.play();
      }
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  }

  // Start recording voice via microphone
  const startRecording = async () => {
    setResError('');
    setFeedback('');

    Mp3Recorder.start()
      .then(() => {
        setIsRecording(true);
      })
      .catch((e) =>
        setError(
          'Error accessing microphone. Please, give permission to access microphone.'
        )
      );
  };

  // stop recording and send it to backend
  const stopRecording = async () => {
    setResError('');
    setFeedback('');

    Mp3Recorder.stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        setIsRecording(false);

        // do what ever you want with buffer and blob
        const formData = new FormData();
        formData.append('file', blob);

        await axios
          .post(server_url + 'audio', formData)
          .then(async (res) => {
            setResData((resData) => [...resData, 'Me: ' + res.data.text]);

            await axios
              .post(server_url + 'audiotext', {
                text: res.data.text,
                history: JSON.parse(sessionStorage.getItem('history')),
                memory: JSON.parse(sessionStorage.getItem('memory')),
                maxTokens: parseInt(sessionStorage.getItem('maxTokens')),
                tokens: parseInt(sessionStorage.getItem('tokens')),
              })
              .then((res) => {
                if (res.data?.error === undefined) {
                  setResData((resData) => [
                    ...resData,
                    `${cases[selectedOptionValue][0]?.role}: ` + res.data.text,
                  ]);
                  handleTextToSpeech(res.data.text);

                  sessionStorage.setItem(
                    'memory',
                    JSON.stringify(res.data.memory)
                  );
                  sessionStorage.setItem('tokens', res.data.tokens.toString());
                  sessionStorage.setItem(
                    'history',
                    JSON.stringify(res.data.history)
                  );
                  sessionStorage.setItem(
                    'maxTokens',
                    res.data.maxTokens.toString()
                  );
                } else {
                  setResData((resData) => [
                    ...resData,
                    `${cases[selectedOptionValue][0]?.role}: ` + res.data.error,
                  ]);
                  handleTextToSpeech(res.data.error);
                }
              })
              .catch((error) => {
                setResError('Error retrieving message. Try again later.');
              });
          })
          .catch((e) => {
            setResError('Error retrieving message. Try again later.');
          });
      });
  };

  // End conversation and get feedback
  const endConversation = () => {
    setConversationEnded(true);
    setFeedbackEnabled(true);
    setFeedback('');
  };

  const getFeedback = async () => {
    setFeedback('');
    setFeedbackError('');
    setIsLoading(true);

    try {
      const instructions ="Instructions: Based on the chat dialogue between me and the interviewer, please provide constructive feedback and criticism for me, NOT the interviewer. For all responses, comment on collaboration skills, collegiality, communication skills, logical thought process, interest in the discipline, interest in the program location, leadership skills, professionalism, scholarly activities, engagement, and self-awareness. Feedback shoud be taken in the context of the interview and the medical specialty applied for. For personal questions (e.g., tell me about yourself, CV, uniqueness), comment on enthusiasm, communication skills, self-awareness, and understanding of the specialty. For program knowledge questions (e.g., what do you hope to gain from the program?), comment on whether the applicant understands the program and has established contacts. For candidate asset questions (e.g., what can you bring to our program?), comment on initiative, leadership, engagement, humility, willingness to teach peers, willingness to get involved in the program. For specialty selection questions (e.g., why this specialty?), comment on passion, personal reflection, understanding of the sacrifices required, understands of the specialty, understanding of the lifestyle. For career fulfillment questions (e.g., what are your long term objectives?), comment on self-reflection. For self-reflection questions (e.g., weaknesses, strengths), comment on sincerity, self-criticism, insightfulness, and efforts to overcome weaknesses. For initiativ questions, comment on leadership, self-confidence, interpersonal relations, motivation, and communication skills. For understanding of the healthcare system questions, comment on communication skills, sociability, respect of peers and colleagues, clinical assessment, self-criticism, and professionalism. Do not make anything up.";

      const conversation = JSON.parse(sessionStorage.getItem('history')).join('\n');
  
      const fullPrompt = `${instructions}\n\n${conversation}`;
      let innerText = '';

      if (conversation.length > 0) {
        // Fetch the response from the OpenAI API with the signal from AbortController
        const response = await fetch(
          'https://api.openai.com/v1/chat/completions',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
            },
            body: JSON.stringify({
              model: 'gpt-4-1106-preview',
              temperature: 0.5,
              top_p: 1,
              messages: [
                {
                  role: 'user',
                  content: fullPrompt,
                },
              ],
              stream: true,
            }),
          }
        );
        // Read the response as a stream of data
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setIsLoading(false);
            break;
          }
          // Massage and parse the chunk of data
          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');
          const parsedLines = lines
            .map((line) => line.replace(/^data: /, '').trim()) // Remove the "data: " prefix
            .filter((line) => line !== '' && line !== '[DONE]') // Remove empty lines and "[DONE]"
            .map((line) => JSON.parse(line)); // Parse the JSON string

          for (const parsedLine of parsedLines) {
            const { choices } = parsedLine;
            const { delta } = choices[0];
            const { content } = delta;
            // Update the UI with the new content
            if (content) {
              innerText += content;
              setFeedback(innerText);
            }
          }
        }
      } else {
        setFeedback('No conversation to provide feedback on.');
        setIsLoading(false);
      }
    } catch (error) {
      setFeedbackError('Error occurred while generating. Please, try again.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // if (!noRender.current) {
    //   setError('');

    //   // first time set options
    //   axios
    //     .get(server_url + 'getcases', {
    //       headers: {
    //         'ngrok-skip-browser-warning': '1231',
    //       },
    //     })
    //     .then((res) => {
    //       setOptions(res.data.cases);
    //     })
    //     .catch((e) => {
    //       setError('Error fetching options. Please, try again later.');
    //     });

    //   noRender.current = true;
    // }
  }, []);

  const [isMuted, setIsMuted] = useState(false);
  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    const audios = document.querySelectorAll('audio');
    for (let i = 0; i < audios.length; i++) {
      audios[i].muted = isMuted;
    }
  }, [isMuted]);

  return (
    <div>
      <p className='commonPara'>
        Which clinical scenario would you like to practice with? You can search for a specific scenario by typing in the dropdown menu.
      </p>

      <div>
        <Switch
            className="muteSwitch"
            checkedChildren={<FontAwesomeIcon icon={faVolumeUp} />}
            unCheckedChildren={<FontAwesomeIcon icon={faVolumeMute} />}
            checked={!isMuted}
            onChange={toggleMute}
          />
      </div>

      <Select
        showSearch
        className={theme === 'light' ? 'light' : ''}
        placeholder="Select One"
        defaultValue="Select One"
        onChange={handleChange}
        suffixIcon={<CaretDownOutlined />}
        options={options?.map((option) => ({ value: option, label: option }))}
        disabled={isLoading}
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
    />

      {selectedOptionValue && (
        <div>
          <p className='commonPara'>You selected: {selectedOptionValue}</p>

          <p className='commonPara'>
            <b>
              {selectedResValue &&
                selectedResValue?.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
            </b>
          </p>

          <hr
            className={`commonPara hrline ${
              theme === 'light' ? 'btnlight' : 'btndark'
            }`}
          />
        </div>
      )}

      {resData?.map((resData) => (
        <div className='response'>
          <p className='commonPara'>{resData}</p>
        </div>
      ))}

      {resError && (
        <div
          className='commonPara'
          style={{ fontWeight: 'bold', color: 'red', fontSize: 18 }}
        >
          {resError}
        </div>
      )}

      <div>
        {selectedOptionValue && !isRecording && (
          <button
            className={`btn ${theme === 'light' ? 'btnlight' : 'btndark'} ${
              isConversationEnded ? 'btndisabled' : ''
            }`}
            onClick={startRecording}
            disabled={isRecording}
          >
            Start
          </button>
        )}

        {isRecording && (
          <button
            className={`btn ${theme === 'light' ? 'btnlight' : 'btndark'} ${
              isConversationEnded ? 'btndisabled' : ''
            }`}
            onClick={stopRecording}
            disabled={!isRecording}
          >
            Stop
          </button>
        )}

        {error && (
          <div
            className='commonPara'
            style={{ fontWeight: 'bold', color: 'red', fontSize: 18 }}
          >
            {error}
          </div>
        )}

        {resData.length > 0 && (
          <>
            <button
              className={`endbtn ${theme === 'light' ? 'btnlight' : 'btndark'}
              ${isConversationEnded ? 'btndisabled' : ''}`}
              onClick={endConversation}
              disabled={isConversationEnded}
            >
              End conversation
            </button>

            {isConversationEnded && (
              <hr
                className={`commonPara hrline ${
                  theme === 'light' ? 'btnlight' : 'btndark'
                }`}
                style={{ marginBottom: 10 }}
              />
            )}

            <button
              className={`endbtn ${
                theme === 'light' ? 'btnlight' : 'btndark'
              } ${!isFeedbackEnabled ? 'btndisabled' : ''}`}
              onClick={getFeedback}
              disabled={isLoading}
            >
              Get Feedback
            </button>

            <div className='commonPara feedbackpara'>
              {feedback && (
                <>
                  <div className='feedbackformat'>{feedback}</div>
                </>
              )}
            </div>

            {feedbackError && (
              <div style={{ fontWeight: 'bold', color: 'red', fontSize: 18 }}>
                {feedbackError}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Selector;