import React from "react";
import Main from "./compoenent/main/Main";
import "./global.scss";

function App() {
  return (
    <>
      <Main />
    </>
  );
}

export default App;
